var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('el-col',{attrs:{"sm":24,"md":14}},[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Nuevo Administrador")])]),_c('div',{staticClass:"text item"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('el-form',{attrs:{"label-position":"top","label-width":"100px","model":_vm.form}},[_c('el-image',{attrs:{"src":_vm.$urlGlobalApi + _vm.form.image}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})])]),_c('el-form-item',{attrs:{"label":"Foto de Perfil"}},[_c('el-upload',{attrs:{"action":"","drag":"","name":"image","http-request":_vm.postImage,"on-success":_vm.handleAvatarSuccess,"multiple":""}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("Suelta tu foto aquí o "),_c('em',[_vm._v("haz clic para cargar")])]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v("Solo archivos jpg/png con un tamaño menor de 500kb")])])],1),_c('el-form-item',{attrs:{"label":"Nombre","size":"mini"}},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Descripción","size":"mini"}},[_c('ValidationProvider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Correo electrónico","size":"mini"}},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Rol","size":"mini"}},[_c('ValidationProvider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{attrs:{"placeholder":"Nivel"},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}},_vm._l((_vm.roles),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Contraseña","size":"mini"}},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|confirmed:passwordc|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"show-password":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Confirmar contraseña","size":"mini"}},[_c('ValidationProvider',{attrs:{"name":"password confirmation","rules":"required","vid":"passwordc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"show-password":""},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',[_c('el-button',{attrs:{"size":"mini","type":"primary","icon":"el-icon-upload2","disabled":invalid},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onStore($event)}}},[_vm._v(" Agregar")])],1)],1)]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }